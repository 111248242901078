export const expiryFormat = string => {
  return string
    .replace(
      /[^0-9]/g,
      '' // To allow only numbers
    )
    .replace(
      /^([2-9])$/g,
      '0$1' // To handle 3 > 03
    )
    .replace(
      /^(1{1})([3-9]{1})$/g,
      '0$1/$2' // 13 > 01/3
    )
    .replace(
      /^0{1,}/g,
      '0' // To handle 00 > 0
    )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
      '$1/$2' // To handle 113 > 11/3
    );
};

const luhnCheck = val => {
  var sum = 0;
  for (var i = 0; i < val.length; i++) {
    var intVal = parseInt(val.substr(i, 1));
    if (i % 2 === 0) {
      intVal *= 2;
      if (intVal > 9) {
        intVal = 1 + (intVal % 10);
      }
    }
    sum += intVal;
  }
  return sum % 10 === 0;
};

export const validateCardNumber = number => {
  const regex = new RegExp('^[0-9]{15,16}$');
  let ccNumber = number.trim();
  ccNumber = ccNumber.split(' ').join('');
  ccNumber = ccNumber.replace(/\s+/g, '');
  if (!regex.test(ccNumber)) return false;

  return luhnCheck(ccNumber);
};

export const cleanCardNumber = number => {
  let ccNumber = number.trim();
  ccNumber = ccNumber.split(' ').join('');
  ccNumber = ccNumber.replace(/\s+/g, '');
  return ccNumber;
};

export const cardNumberFormat = value => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};
