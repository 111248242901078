import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div``;

export const OuterBorder = styled.div`
  border: 1px solid #ccd2e3;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
`;

export const Header = styled.div`
  display: flex;
  flex-deriction: row;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;

  &:after {
    content: ' ';
    background-image: url(/images/4-card-logos.png);
    background-repeat: no-repeat;
    display: block;
    width: 174px;
    height: 25px;
    position: absolute;
    right: 14px;
    @media ${queries.sm} {
      right: 10px;
      background-image: url(/images/card-logos.svg);
      width: 84px;
    }
  }

  @media ${queries.sm} {
    padding: 14px 10px;
  }
`;

export const OptionRadio = styled.div`
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #ccd2e3;
  border-radius: 20px;
  &.active {
    border: 7px solid #001c72;
  }
  @media ${queries.sm} {
    margin-right: -5px;
  }
`;

export const OptionLabel = styled.div`
  font-size: 14px;
  font-family: Neuzeit Grotesk;
  display: block;
  margin-top: 5px;
  margin-left: 10px;
  color: #001c72;
`;

export const Body = styled.div`
  background-color: #f8f8f8;
  min-height: 100px;
  border-top: 1px solid #ccd2e3;
  border-radius: 0 0 4px 4px;
  padding: 25px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ColOne = styled.div`
  width: 50%;
  padding-right: 8px;
`;

export const ColTwo = styled.div`
  width: 50%;
  padding-left: 8px;
`;

export const InputContainer = styled.div`
  margin-top: 20px;
  &.mt-0 {
    margin-top: 0;
  }
`;
export const InputLabel = styled.div`
  color: #001c72;
  font-family: Utopia Std;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const UpdateButton = styled.button`
  border-radius: 4px;
  background-color: #bc2e3e;
  text-align: center;
  font-size: 16px;
  padding: 15px 14px 10px;
  font-family: Neuzeit Grotesk;
  border: none;
  outline: none;
  color: #fff;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  letter-spacing: 2px;
  &:hover {
    background-color: #a02734;
  }

  ${({ buttonClicked }) => {
    return buttonClicked
      ? `
    &:before {
      content: "";
      height: 22px;
      width: 100%;
      background-image: url("/images/three-dots.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
    `
      : ``;
  }}

  ${({ buttonDisabled }) => {
    return buttonDisabled
      ? `
    opacity: 0.5;
    cursor:initial;
    &:hover {
      background-color:#BC2E3E;
    }
    `
      : ``;
  }}
`;
export const BottomText = styled.div`
  font-family: Neuzeit Grotesk;
  color: #001c72;
  font-size: 14px;
  background-image: url(/images/padlock.svg);
  background-repeat: no-repeat;
  background-position: 0 3px;
  padding-left: 30px;
  margin-top: 15px;
  padding-top: 8px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 100px;
  @media ${queries.sm} {
    padding-right: 0;
    padding-left: 20px;
    text-align: center;
  }
`;

export const BottomTextContainer = styled.div`
  position: relative;
`;

export const BottomLogos = styled.div`
  @media ${queries.sm} {
    position: relative;
    height: 20px;
    margin: 10px auto 0;
    width: 80px;
  }

  &:after {
    content: ' ';
    display: block;
    width: 39px;
    height: 25px;
    background-image: url(/images/secure-mcaffee.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:before {
    content: ' ';
    display: block;
    width: 39px;
    height: 25px;
    background-image: url(/images/secure-norton.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    right: 50px;
    top: 0;
  }
`;
